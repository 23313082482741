import {
  CLIENT_ROLE_GROUP,
  FUNDER_ROLE_GROUP,
  LENDFLOW_ROLE_GROUP
} from "@/helpers/constants";
import type { Role } from "@/models/options";
import type { IUser } from "@/models/users";

import { computed } from "vue";
import { useStore } from "vuex";

export const useAccess = () => {
  const defaultAccessRoles = new Set([
    ...LENDFLOW_ROLE_GROUP,
    ...CLIENT_ROLE_GROUP,
    ...FUNDER_ROLE_GROUP
  ]);
  const { getters } = useStore();
  const user = computed<IUser>(() => getters["auth/user"]);
  const userRoles = computed<Role[]>(() => user.value.roles);

  const hasDefaultAccess = computed(() =>
    userRoles.value.some((role) => defaultAccessRoles.has(role))
  );

  const hasAccess = (roles: Set<Role>) =>
    hasDefaultAccess.value || userRoles.value.some((role) => roles.has(role));

  return {
    hasAccess
  };
};
