<template>
  <action-menu-wrapper
    data-cy="document-list-item-menu"
    :items="menuItems"
    :offset="offset"
    teleport
  />

  <confirm-modal
    v-if="showDeleteModal"
    :title="$t('DEALS.DOCUMENTS.DELETE_MODAL_TITLE')"
    :description="
      $t('DEALS.DOCUMENTS.DELETE_DOC_PROMPT', {
        name: model.original_name
      })
    "
    :confirm-button="$t('COMMON.DELETE')"
    :close="() => (showDeleteModal = false)"
    confirm-button-type="error"
    @confirm="handleDeleteClick"
  />

  <document-edit-modal
    v-if="showDocEditModal"
    :document="model"
    :close="() => (showDocEditModal = false)"
  />
</template>

<script lang="ts">
import { ref, computed, type PropType } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import { downloadFile } from "@/helpers/files";
import {
  CLIENT_ADMIN,
  CLIENT_UNDERWRITER,
  LENDFLOW_ROLE_GROUP
} from "@/helpers/constants";
import { useDeals } from "@/hooks/deals";
import { useAccess } from "@/hooks/access";
import type { IFile } from "@/models/common";
import type { MenuItem } from "@/components/ui/ActionMenuWrapperV2.vue";
</script>

<script setup lang="ts">
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import DocumentEditModal from "./DocumentEditModal.vue";
import ActionMenuWrapper from "@/components/ui/ActionMenuWrapperV2.vue";

const props = defineProps({
  model: {
    type: Object as PropType<IFile>,
    required: true
  }
});

const offset = { left: -82, top: 43 };

const { t } = useI18n();
const { hasAccess } = useAccess();
const { canEditDealProgress } = useDeals();
const { dispatch } = useStore();

const showDeleteModal = ref(false);
const showDocEditModal = ref(false);

const menuItems = computed(() => {
  const showEditActions = hasAccess(new Set(editRoles.value));

  const items: Array<MenuItem> = [
    {
      handler: () => (showDocEditModal.value = true),
      label: t("COMMON.EDIT")
    },
    {
      handler: download,
      label: t("DEALS.DOCUMENTS.DOWNLOAD")
    },
    {
      handler: () => (showDeleteModal.value = true),
      label: t("COMMON.DELETE")
    }
  ];

  return showEditActions ? items : [items[1]];
});

const editRoles = computed(() => [
  ...LENDFLOW_ROLE_GROUP,
  ...(canEditDealProgress.value ? [CLIENT_UNDERWRITER, CLIENT_ADMIN] : [])
]);

const handleDeleteClick = async () => {
  await dispatch("applications/deleteFile", {
    id: props.model.id
  });
};

const download = () =>
  downloadFile(props.model.file_url, {}, props.model.original_name);
</script>
